import TYPES from '@/types';

// Application
import {
  GetOnBoardingCurrentStepQuery,
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import { GetHasKuspitAccountQuery }
  from '@/modules/my-investment/kuspit/customer-has-account/application/queries';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import Inject from '@/modules/shared/domain/di/inject';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';

export default class ContractSavingsViewModel {
  @Inject(TYPES.GET_MY_INVESTMENT_ON_BOARDING_CURRENT_STEP_QUERY)
  private readonly getOnBoardingCurrentStepQuery!: GetOnBoardingCurrentStepQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  readonly getHasKuspitAccountQuery!: GetHasKuspitAccountQuery;

  i18n_namespace = 'components.contract-savings';

  loading = true;

  loading_current_step = true;

  SERVICES: Record<string, Array<any>> = {
    allianz: [
      {
        name: '',
        component: 'ContractSavingsBeforeStart',
      },
      {
        name: '',
        component: 'ContractSavingsInvestmentInsuranceAllianz',
        number: 1,
      },
      {
        name: '',
        component: 'ContractSavingsStart',
        number: 2,
      },
      {
        name: '',
        component: 'ContractSavingsPersonalInformationProofOfAddressAllianz',
        number: 3,
      },
      {
        name: '',
        component: 'ContractSavingsPersonalInformationContact',
        number: 4,
      },
      {
        name: '',
        component: 'ContractSavingsProfilingPersonalInformation',
        number: 5,
      },
      {
        name: '',
        component: 'ContractSavingsProfilingDepositsAndWithdrawals',
        number: 6,
      },
      {
        name: '',
        component: 'ContractSavingsProfilingWorkAllianz',
        number: 7,
      },
      {
        name: '',
        component: 'ContractSavingsProfilingInvestmentProfile',
        number: 8,
      },
      {
        name: '',
        component: 'ContractSavingsProfilingResults',
        number: 9,
      },
      {
        name: '',
        component: 'ContractSavingsBeneficiaries',
        number: 10,
      },
      {
        name: '',
        component: 'ContractSavingsInvestmentsAllianz',
        number: 11,
      },
      {
        name: '',
        component: 'ContractSavingsAlmostReady',
        number: 12,
      },
      {
        name: '',
        component: 'ContractSavingsContributionsInitialContribution',
        number: 13,
      },
      {
        name: '',
        component: 'ContractSavingsContributionsScheduleContributions',
        number: 14,
      },
      {
        name: '',
        component: 'ContractSavingsFinancialInformation',
        number: 15,
      },
      {
        name: '',
        component: 'ContractSavingsCollectionFinishCollection',
        number: 16,
      },
      {
        name: '',
        component: 'AlmostCompleted',
        number: 17,
      },
      {
        name: '',
        component: 'FinishingAllianzProcess',
        number: 18,
      },
    ],
    kuspit: [
      {
        name: '',
        component: 'KuspitUser',
        number: 0,
      },
      {
        name: 'on_boarding_kuspit_terms_and_conditions',
        component: 'ContractSavingsKuspitAccount',
        number: 1,
      },
      {
        name: '',
        component: 'ContractSavingsBeforeStart',
        number: null,
      },
      {
        name: 'on_boarding_kuspit_personal_information',
        component: 'ContractSavingsStart',
        number: 2,
      },
      {
        name: 'on_boarding_kuspit_bank_information',
        component: 'ContractSavingsFinancialInformation',
        number: 3,
      },
      {
        name: 'on_boarding_kuspit_proof_of_address',
        component: 'ContractSavingsPersonalInformationAddress',
        number: 4,
      },
      {
        name: 'on_boarding_kuspit_contact',
        component: 'ContractSavingsPersonalInformationContact',
        number: 5,
      },
      {
        name: 'on_boarding_kuspit_financial_information',
        component: 'ContractSavingsAdditionalInformationFinancialInformation',
        number: 6,
      },
      {
        name: 'on_boarding_kuspit_activity',
        component: 'ContractSavingsProfilingWorkKuspit',
        number: 7,
      },
      {
        name: 'on_boarding_kuspit_geolocation',
        component: 'ContractSavingsGeolocation',
        number: 8,
      },
      {
        name: '',
        component: 'KuspitAboutToComplete',
        number: 9,
      },
      {
        name: '',
        component: 'ContractSavingsCreatingAccountKuspit',
        number: 10,
      },
      {
        name: '',
        component: 'ContractSavingsCredentialsKuspit',
        number: 11,
      },
    ],
  };

  SECTIONS = {
    allianz: [1, 5, 10, 11, 13, 15],
    kuspit: [2, 3, 5, 6],
  };

  small_screen: null | boolean = null;

  current_step = 0;

  section_data = {
    title: '',
    step: null,
  };

  investment_provider_id = '';

  ignore_first_step = 0;

  ignore_second_step = 0;

  is_a_link = false;

  get searchOnBoardingSteps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get hasKuspitAccount() {
    return this.getHasKuspitAccountQuery.execute();
  }

  setInvestmentProviderId = (
    investment_providers: Array<InvestmentProviderEntity>, service: string,
  ) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === service,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  checkIfStepIsAccountLinking = (service: string, first_step?: OnBoardingStepEntity) => {
    if (service === 'kuspit') {
      this.ignore_first_step = first_step?.current_step === 'contract_selection_or_account_linking_step' ? 1 : 0;
      return first_step?.current_step === 'contract_selection_or_account_linking_step'
        && first_step.payload.i_am_user && !this.is_a_link;
    }
    this.ignore_first_step = 0;
    return false;
  }

  checkIfStepIsKuspitStatus = (service: string, first_step?: OnBoardingStepEntity) => {
    this.ignore_second_step = 0;

    if (service === 'kuspit' && first_step) {
      if (first_step!.payload.status === 'account_without_contract') {
        this.is_a_link = true;
        this.ignore_second_step = first_step!.payload.status === 'account_without_contract' ? 1 : 0;
      } else {
        this.ignore_second_step = first_step?.current_step === 'on_boarding_kuspit_status' ? 1 : 0;
      }
    }
  }

  setCurrentStep = async (service: string) => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers, service);

    const steps = await this.searchOnBoardingSteps;

    const link_or_creation_kuspit_step = steps.find(
      (step) => step.current_step === 'contract_selection_or_account_linking_step',
    );

    const link_kuspit_status_step = steps.find(
      (step) => step.current_step === 'on_boarding_kuspit_status',
    );

    this.checkIfStepIsKuspitStatus(service, link_kuspit_status_step);

    if (steps.length && this.checkIfStepIsAccountLinking(service, link_or_creation_kuspit_step)) {
      this.current_step = 0;
    } else {
      let last_filled_step = this.SERVICES[service].findIndex(
        (step) => step.number === (steps.length - this.ignore_first_step - this.ignore_second_step),
      );

      const allianz_investor_step = steps.find(
        (step) => step.current_step === 'investor_profile',
      );

      if (allianz_investor_step && allianz_investor_step.payload.internal_step
        && allianz_investor_step.payload.internal_step !== 'InvestorProfileInvestmentType') {
        last_filled_step -= 1;
      }
      this.current_step = last_filled_step === -1 ? 0 : last_filled_step;
    }
  }

  verifyKuspitAccount = async () => {
    const has_kuspit_account = await this.hasKuspitAccount;
    if (has_kuspit_account.has_account) {
      this.current_step = this.SERVICES.kuspit.length - 1;
    }
  }

  initialize = async (service: string) => {
    await this.setCurrentStep(service);

    if (service === 'kuspit') {
      await this.verifyKuspitAccount();
    }

    this.loading = false;
  }
}
