












































































































































import {
  Vue,
  Component,
  PropSync,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import ContractSavingsViewModel from '@/vue-app/view-models/components/contract-savings-view-model';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';

@Component({
  name: 'ContractSavings',
  components: {
    ContractSavingsBeforeStart: () => import('@/vue-app/components/contract-savings/BeforeStart.vue'),
    ContractSavingsKuspitAccount: () => import('@/vue-app/components/contract-savings/before-start/ContractSavingsKuspitAccount.vue'),
    ContractSavingsInvestmentInsuranceAllianz: () => import('@/vue-app/components/contract-savings/before-start/ContractsSavingsInvestmentInsuranceAllianz.vue'),
    ContractSavingsInvestmentsAllianz: () => import('@/vue-app/components/contract-savings/investments/ContractSavingsInvestmentsAllianz.vue'),
    ContractSavingsAlmostReady: () => import('@/vue-app/components/contract-savings/investments/ContractSavingsAlmostReady.vue'),
    ContractSavingsVerifyNipKuspit: () => import('@/vue-app/components/contract-savings/verify-nip/ContractSavingsVerifyNipKuspit.vue'),
    ContractSavingsStart: () => import('@/vue-app/components/contract-savings/Start.vue'),
    ContractSavingsPersonalInformationAddress: () => import('@/vue-app/components/contract-savings/ContractSavingsPersonalInformationAddress.vue'),
    ContractSavingsPersonalInformationProofOfAddressAllianz: () => import('@/vue-app/components/contract-savings/personal-information/ContractSavingsPersonalInformationProofOfAddressAllianz.vue'),
    ContractSavingsPersonalInformationContact: () => import('@/vue-app/components/contract-savings/ContractSavingsPersonalInformationContact.vue'),
    ContractSavingsProfilingDepositsAndWithdrawals: () => import('@/vue-app/components/contract-savings/ContractSavingsProfilingDepositsAndWithdrawals.vue'),
    ContractSavingsProfilingWorkAllianz: () => import('@/vue-app/components/contract-savings/profiling/work/ContractSavingsProfilingWorkAllianz.vue'),
    ContractSavingsProfilingWorkKuspit: () => import('@/vue-app/components/contract-savings/profiling/work/ContractSavingsProfilingWorkKuspit.vue'),
    ContractSavingsProfilingInvestmentProfile: () => import('@/vue-app/components/contract-savings/ContractSavingsProfilingInvestmentProfile.vue'),
    ContractSavingsProfilingResults: () => import('@/vue-app/components/contract-savings/ContractSavingsProfilingResults.vue'),
    ContractSavingsBeneficiaries: () => import('@/vue-app/components/contract-savings/ContractSavingsBeneficiaries.vue'),
    ContractSavingsContributionsInitialContribution: () => import('@/vue-app/components/contract-savings/ContractSavingsContributionsInitialContribution.vue'),
    ContractSavingsContributionsScheduleContributions: () => import('@/vue-app/components/contract-savings/ContractSavingsContributionsScheduleContributions.vue'),
    ContractSavingsFinancialInformation: () => import('@/vue-app/components/contract-savings/financial-information/ContractSavingsFinancialInformation.vue'),
    ContractSavingsCollectionFinishCollection: () => import('@/vue-app/components/contract-savings/collection/ContractSavingsCollectionFinishCollection.vue'),
    ContractSavingsAdditionalInformationFinancialInformation: () => import('@/vue-app/components/contract-savings/additional-information/ContractSavingsAdditionalInformationFinancialInformation.vue'),
    ContractSavingsProfilingPersonalInformation: () => import('@/vue-app/components/contract-savings/profiling/personal-information/ContractSavingsProfilingPersonalInformation.vue'),
    ContractSavingsCollectionReminder: () => import('@/vue-app/components/contract-savings/collection/ContractSavingsCollectionReminder.vue'),
    AlmostCompleted: () => import('@/vue-app/components/contract-savings/AlmostCompleted.vue'),
    KuspitUser: () => import('@/vue-app/components/contracts/KuspitUser.vue'),
    KuspitAboutToComplete: () => import('@/vue-app/components/contract-savings/KuspitAboutToComplete.vue'),
    KuspitCongratulations: () => import('@/vue-app/components/contract-savings/KuspitCongratulations.vue'),
    ContractSavingsGeolocation: () => import('@/vue-app/components/contract-savings/ContractSavingsGeolocation.vue'),
    ContractSavingsCreatingAccountKuspit: () => import('@/vue-app/components/contract-savings/creating-account/ContractSavingsCreatingAccountKuspit.vue'),
    ContractSavingsCredentialsKuspit: () => import('@/vue-app/components/contract-savings/ContractSavingsCredentialsKuspit.vue'),
    FinishingAllianzProcess: () => import('@/vue-app/components/contract-savings/allianz/finishing-allianz-process.vue'),
  },
})
export default class ContractSavings extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  contract_savings_view_model = Vue.observable(new ContractSavingsViewModel());

  current_component = this.contract_savings_view_model
    .SERVICES[this.service][this.contract_savings_view_model.current_step];

  @Watch('contract_savings_view_model.investmentProviders')
  onInvestmentProvidersChange(investment_providers: Array<InvestmentProviderEntity>) {
    this.contract_savings_view_model.setInvestmentProviderId(investment_providers, this.service);
  }

  goBack() {
    // eslint-disable-next-line
    // @ts-ignore
    if (typeof this.$refs.current_component_ref.prevStep === 'function') {
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.current_component_ref.prevStep();
    }
  }

  nextStep() {
    if (
      this.contract_savings_view_model.current_step
      === this.contract_savings_view_model.SERVICES[this.service].length - 1
    ) {
      this.synced_is_open = false;
      this.contract_savings_view_model.current_step = 0;
    } else if (this.contract_savings_view_model.is_a_link
      && this.contract_savings_view_model.current_step === 11 && this.service === 'kuspit') {
      this.endProcess();
    } else {
      this.contract_savings_view_model.current_step += 1;
    }
    this.contract_savings_view_model.loading_current_step = true;
  }

  prevStep() {
    if (this.contract_savings_view_model.current_step === 0) {
      this.synced_is_open = false;
    } else {
      this.contract_savings_view_model.current_step -= 1;
    }
    this.contract_savings_view_model.loading_current_step = true;
  }

  endProcess() {
    this.synced_is_open = false;
    this.contract_savings_view_model.current_step = 0;
    window.location.href = '/';
  }

  updateStep(new_step: number) {
    this.contract_savings_view_model.current_step = new_step;
  }

  loadingStep(loading: boolean) {
    this.contract_savings_view_model.loading_current_step = loading;
  }

  setSectionTitle(current_step: number) {
    for (
      let index = 0;
      index < this.contract_savings_view_model.SECTIONS[this.service].length - 1;
      index += 1) {
      if (
        current_step >= this.contract_savings_view_model.SECTIONS[this.service][index]
        && current_step < this.contract_savings_view_model.SECTIONS[this.service][index + 1]
      ) {
        this.contract_savings_view_model.section_data.title = this.$t(
          `${this.contract_savings_view_model.i18n_namespace}.${this.service}.steps[${index}]`,
        ) as string;
      }
    }
  }

  @Watch('contract_savings_view_model.current_step')
  handleStepChange(new_current_step: number) {
    this.setSectionTitle(new_current_step);
    this.current_component = this.contract_savings_view_model
      .SERVICES[this.service][new_current_step];
  }

  @Watch('service')
  handleServiceChange(new_service: SavingsServiceType) {
    this.contract_savings_view_model.initialize(new_service);
  }

  async created() {
    this.setSectionTitle(this.contract_savings_view_model.current_step);
    await this.contract_savings_view_model.initialize(this.service);
  }

  closeModal() {
    this.synced_is_open = false;
  }
}

